import React from "react";
import PropTypes from "prop-types";
import '.././styles/style.css';

export default class Contact extends React.Component {
render() {
  return (
    <div id="my-contact" className="container text-center my-5">
      <h1 id="contact" className={"mb-3 " + this.props.fadeInLeft}>
        Contact me
      </h1>
      <form
        className={this.props.shake}
        action="https://formspree.io/xoqaogdj"
        method="POST">
        <div className="form-group" style={{marginTop:5+"em"}}>
          <div className="row" >
            <div className="col-12 col-sm-12 col-md-6 mx-auto">
              <input
                type="text"
                name="name"
                className="form-control form-control-lg"
                id="name"
                placeholder="Name"
              />
            </div>
          </div>
        </div>
        <div className="form-group hidden">
          <div className="row" >
            <div className="col-12 col-sm-12 col-md-6 mx-auto">
              <input
                type="email"
                name="_replyto"
                className="form-control form-control-lg"
                id="exampleFormControlInput1"
                placeholder="Your email"
              />
            </div>
          </div>
        </div>
        <div className="form-group hiddenRight">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 mx-auto">
              <textarea
                name="message"
                className="form-control form-control-lg"
                id="exampleFormControlTextarea1"
                rows="10"
                placeholder="Write your message..."
              />
            </div>
          </div>
        </div>
        <div className="row text-md-right text-sm-center">
          <div className="col-12 col-sm-12 col-md-6 mx-auto">
            <button
              type="submit"
              className="btn btn-dark mb-2 hidden">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
}

Contact.propTypes = {
fadeInLeft: PropTypes.string,
shake: PropTypes.string
};
